import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';
import LazyLoad from 'react-lazyload';
import { useHistory } from 'react-router';
import { replaceNaeringUrltag as replaceTag } from '../../../../../utils/helpers/replaceNaeringUrltag';
import {
  DepartmentTag,
  Employee
} from '../../../../../__types__/generated/cluster';

interface ITab {
  data: Partial<Employee>;
  onClick?: () => void;
}

interface IBrokerImage {
  image: string | null | undefined;
}

const BrokerImage: React.FC<IBrokerImage> = ({ image }) => {
  const [loaded, setLoaded] = useState(false);
  return (
    <>
      <StyledBrokerImageWrapper>
        <StyledBrokerImage
          loaded={loaded.toString()}
          style={{ background: `url(${image}) no-repeat #fff` }}
        />
        {image ? (
          <img src={image} onLoad={() => setLoaded(true)} alt="bilde" />
        ) : null}
      </StyledBrokerImageWrapper>
    </>
  );
};

export const Broker: React.FC<ITab> = ({ data }) => {
  const history = useHistory();
  const naeringOnly = () => {
    if (data?.departments?.length !== 1) return false;
    return (
      data?.departments
        ?.find((e) => e?.tags?.length)
        ?.tags?.includes(DepartmentTag?.Commercial) ?? false
    );
  };

  return (
    <StyledBrokerItem
      onClick={() => {
        const { employeeAlias } = data;
        if (!employeeAlias) return;
        if (naeringOnly()) {
          const departmentAlias = data?.departments?.find(
            (e) => e?.tags?.includes(DepartmentTag.Commercial) && e?.alias
          )?.alias;
          if (!departmentAlias) return;
          window.location.href = `https://privatmegleren.no/naering/${replaceTag(
            departmentAlias
          )}/kontakt/${employeeAlias}`;
        } else {
          history.push(`/megler/${employeeAlias}`);
        }
      }}
    >
      <BrokerImage image={data.image?.url ?? ``} />
      <BrokerInfo>
        {data.name ? <span className="name">{data.name}</span> : null}
        {data?.departments?.length
          ? data?.departments
              ?.filter((e) => e?.alias)
              ?.map((office, index) => {
                if (index !== 0) return null;
                return (
                  <span key={`title_list_${office?.name}`} className="title">
                    <span className="office-title">{data?.title}</span>
                    <span className="office">
                      PrivatMegleren {office?.name}
                    </span>
                  </span>
                );
              })
          : null}
      </BrokerInfo>
    </StyledBrokerItem>
  );
};

const hoverStyle = lighten(0.02, '#1c1c1c');
const StyledBrokerItem = styled.div`
  transition: background 0.2s ease-in-out;
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  width: 100%;
  padding: 1rem;
  background: #1c1c1c;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    cursor: pointer;
    background: ${hoverStyle};
  }

  @media screen and (max-width: 600px) {
    background: none;
    border-bottom: solid 1px rgb(40, 40, 40);
    margin-bottom: 0;
  }
`;

const StyledBrokerImage: any = styled.div`
  transition: opacity 0.15s ease-in-out !important;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 70px;
  height: 70px;
  flex: 0 0 70px;
  background-position: center !important;
  background-size: cover !important;
  opacity: ${(props: any) => (props.loaded === 'true' ? 1 : 0)};
`;

const StyledBrokerImageWrapper: any = styled.div`
  position: relative;
  display: inline-flex;
  width: 70px;
  height: 70px;
  border-radius: 100%;
  background: rgb(40, 40, 40);
  float: left;

  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 1px;
    height: 1px;
    z-index: -1;
  }
`;

const BrokerInfo = styled.div`
  display: inline-flex;
  flex-flow: column;
  margin: 0 0 0 1em;
  float: left;
  span {
    display: block;
    &.name {
      display: block;
      color: #e8c893;
      font-size: 1.2em;
      margin: 0 0 0.3em;
    }
    &.title {
      color: #ddd;
      font-size: 1em;
      margin-bottom: 0.3em;

      .office {
        font-weight: 600;
        color: #ddd;
        font-size: 1em;
      }
    }

    @media screen and (max-width: 600px) {
      &.title {
        margin-bottom: 10px;
        font-size: 0.7em;

        &:last-child {
          margin-bottom: 0;
        }

        .office {
          display: block;
          font-size: 0.7em;
        }
      }
    }
  }
`;
